import _interopRequireDefault2 from "@babel/runtime/helpers/interopRequireDefault";
import _hasClass2 from "./hasClass";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
exports.__esModule = true;
exports.default = addClass;

var _hasClass = _interopRequireDefault(_hasClass2);

function addClass(element, className) {
  if (element.classList) element.classList.add(className);else if (!(0, _hasClass.default)(element, className)) if (typeof element.className === "string") element.className = element.className + " " + className;else element.setAttribute("class", (element.className && element.className.baseVal || "") + " " + className);
}

exports = exports["default"];
export default exports;
export const __esModule = exports.__esModule;